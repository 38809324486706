.benefitsContainer {
  display: flex;
  justify-content: space-between;

  margin-bottom: 200px;
}

.leftSide {
  margin-left: -100px;
}

.leftSideImg {
  max-width: 559px;
  height: 666px;

  margin-right: 177px;
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 90px;
  margin-right: 27px;
}

.rightSide > h2 {
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;

  padding-bottom: 32px;
}

.rightSide > p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;

  padding-bottom: 60px;
}

.benefitsOffers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.benefitsOffers img {
  width: 100px;
  height: 100px;
}

.benefitsOffers > div {
  display: flex;
  align-items: center;
}

.benefitsOffers > div > p {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  padding-left: 24px;
}

.benefitsOffers > div:nth-child(1) {
  padding-right: 56px;
  padding-bottom: 95px;
}

.benefitsOffers > div:nth-child(2) {
  padding-bottom: 95px;
}

.benefitsOffers > div:nth-child(3) {
  padding-right: 56px;
}

@media (max-width: 1400px) {
  .leftSideImg {
    max-width: 500px;
    height: 666px;
    margin-right: 77px;
  }

  .rightSide {
    margin-top: 80px;
  }

  .rightSide > h2 {
    font-weight: 300;
    font-size: 40px;
    padding-bottom: 40px;
  }

  .benefitsOffers > div > p {
    font-size: 22px;
  }

  .benefitsOffers > div:nth-child(1),
  .benefitsOffers > div:nth-child(2),
  .benefitsOffers > div:nth-child(3) {
    padding-bottom: 24px;
  }

  .benefitsContainer {
    margin-bottom: 100px;
  }
}

@media (max-width: 1250px) {
  .rightSide > h2 {
    padding-bottom: 30px;
  }

  .benefitsOffers > div > p {
    font-size: 20px;
    padding-left: 14px;
  }

  .leftSideImg {
    max-width: 400px;
    height: 666px;
    margin-right: 57px;
  }

  .rightSide > p {
    font-size: 20px;
    padding-bottom: 55px;
  }
}

@media (max-width: 1130px) {
  .leftSideImg {
    max-width: 340px;
    height: 666px;
    margin-right: 47px;
  }

  .rightSide > h2 {
    padding-bottom: 25px;
  }
  .rightSide > p {
    padding-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .leftSideImg {
    max-width: 602px;
    height: 594px;
    margin-right: 37px;
  }

  .rightSide {
    margin-top: 80px;
  }

  .rightSide > h2 {
    font-weight: 300;
    font-size: 40px;
    padding-bottom: 40px;
  }

  .rightSide > p {
    display: none;
  }

  .benefitsOffers {
    display: flex;
    flex-direction: column;
  }

  .benefitsOffers > div > p {
    font-size: 18px;
  }

  .benefitsOffers > div:nth-child(1),
  .benefitsOffers > div:nth-child(2),
  .benefitsOffers > div:nth-child(3) {
    padding-bottom: 24px;
  }
}

@media (max-width: 830px) {
  .benefitsContainer {
    margin-bottom: 100px;
  }

  .leftSideImg {
    max-width: 368px;
    height: 494px;
  }

  .rightSide {
    margin-top: 53px;
  }

  .rightSide > h2 {
    font-weight: 700;
    font-size: 26px;
    padding-bottom: 40px;
  }

  .benefitsOffers img {
    width: 80px;
    height: 80px;
  }

  .benefitsOffers > div > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
  }

  .benefitsOffers > div:nth-child(1),
  .benefitsOffers > div:nth-child(2),
  .benefitsOffers > div:nth-child(3) {
    padding-bottom: 16px;
  }

  .benefitsContainer {
    margin-bottom: 80px;
  }
}

@media (max-width: 640px) {
  .leftSideImg {
    max-width: 268px;
    height: 319px;
    margin-right: 27px;
  }

  .benefitsOffers img {
    width: 40px;
    height: 40px;
  }

  .rightSide > h2 {
    font-size: 20px;
    padding-bottom: 16px;
    line-height: 25px;
  }

  .benefitsOffers > div > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-left: 10px;
  }
}

@media (max-width: 408px) {
  .leftSideImg {
    max-width: 230px;
    height: 319px;
    margin-right: 20px;
  }

  .benefitsOffers img {
    width: 35px;
    height: 35px;
  }

  .rightSide > h2 {
    font-size: 18px;
    line-height: 20px;
  }

  .benefitsOffers > div > p {
    padding-left: 8px;
  }
}
