.formContainer {
  margin: 24px;
}

.formHeader {
  display: flex;
  justify-content: space-between;
}

.formHeader > img {
  width: 23px;
  height: 25px;
  cursor: pointer;
}

.formTitle > h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 12px;
}

.formTitle > h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 41px;
}

.fieldRowWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 38px;
  margin-bottom: 24px;
}

.comfortaaFont {
  font-family: "Comfortaa", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
}

.inputs > input,
.inputs > textarea {
  border: none;
  border-bottom: 1px solid #1e1e1e;
  color: #9b9b9b;
  width: 100%;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 6px;
  height: 26px;
}

.inputs > textarea {
  resize: none;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.inputs > textarea::-webkit-scrollbar {
  display: none;
}

.fieldRowWrapper > textarea::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
}

.fieldRowWrapper input[type="file"] {
  border-bottom: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.fieldRowWrapper:last-child {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0;
}

.lastInput {
  display: flex;
  align-items: center;
  position: relative;
}

.fileIcon {
  width: 18px;
  height: 20px;
  cursor: pointer;
}

.customFileInput {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 20px;
}

.customFileInput span {
  flex: 1;
  color: #9b9b9b;

  font-family: "Comfortaa", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  margin-left: 8px;
}

.orderButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.error {
  display: block;
  color: #c2272d;
  font-size: 12px;
  margin-top: 5px;
}

.hideParagraph {
  display: none;
}

@media (max-width: 830px) {
  .hideParagraph {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
  }

  .fieldRowWrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 14px;
  }

  .formContainer {
    margin: 20px;
  }

  .formHeader > img {
    width: 14px;
    height: 14px;
  }

  .formTitle > h2 {
    font-size: 22px;
  }

  .formTitle > h4 {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .comfortaaFont {
    font-size: 14px;
    padding-bottom: 14px;
  }

  .inputs > input,
  .inputs > textarea {
    border: none;

    border-radius: 5px;
    background-color: #f4f4f4;

    font-size: 12px;
    padding: 14px;
    height: 41px;
  }

  .lastInput {
    margin-left: 14px;
  }
}
