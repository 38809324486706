.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 19px;
  border: 1px solid 11eE1E;
  width: 708px;
  background-color: #ffffff;
  margin: 0 auto;
}

.modalBackdrop {
  background: #00000063;
}

.alert {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
}

.alert > p {
  padding-bottom: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.alert > button {
  font-weight: 400;
  font-size: 16px;
  padding: 5px 40px;
  border-radius: 8px;
  border: 1px solid #c2272d;
  cursor: pointer;
}

@media (max-width: 830px) {
  .modalContent {
    width: 408px;
  }

  .alert {
    padding: 20px;
  }

  .alert > p {
    font-size: 16px;
  }

  .alert > button {
    font-size: 14px;
    padding: 5px 30px;
    border-radius: 8px;
  }
}

@media (max-width: 460px) {
  .formModal {
    width: 308px;
    height: 64vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .messageModal {
    width: 308px;
  }
}
