.defaultForm {
  position: absolute; /* Абсолютное позиционирование формы */
  top: 50%; /* Вертикальное выравнивание по центру */
  left: 50%; /* Горизонтальное выравнивание по центру */
  transform: translate(-50%, -50%); /* Смещение для центрирования */
  width: 90%; /* Ширина формы в процентах для отзывчивости */
  max-width: 1432px; /* Максимальная ширина формы */
  padding: 23px 30px 32px 32px; /* Универсальные отступы для внутреннего содержимого */
  background-color: #1e1e1e91; /* Полупрозрачный фон */
  border-radius: 20px; /* Скругление углов формы */
  transition: width 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.formTitle {
  color: #ffffff; /* Цвет заголовка формы */
  font-weight: 300; /* Нажим текста для заголовка */
  font-size: 40px; /* Размер шрифта заголовка, как в дизайне Figma */
  line-height: 48px;
  text-align: center; /* Выравнивание заголовка по центру */
  padding-bottom: 20px; /* Отступ снизу от заголовка */
}

/* Сетка для организации ввода */
.formGrid {
  display: grid; /* Используем сетку для расположения элементов */
  gap: 24px; /* Пробелы между строками для эстетики */
}

/* Строка ввода для выбора локации и времени */
.inputRow {
  display: flex; /* Используем flexbox для выравнивания элементов в строке */
  gap: 33px; /* Пробелы между элементами в строке */
  justify-content: space-between; /* Распределение пространства между элементами строки */
}

/* Общий стиль для оберток выбора */
.selectWrapper,
.dateWrapper {
  position: relative; /* Позиционирование для иконок */
  flex: 1; /* Каждый элемент занимает равную ширину в строке */
}

.selectWrapper {
  max-width: 834px;
  width: 100%;
}

.dateWrapper {
  max-width: 282px;
  width: 100%;
}

/* Общий стиль для селекторов и инпутов */
.locationSelector,
.dateSelector {
  transition: font-size 0.3s ease-in-out, padding-left 0.3s ease-in-out;
  width: 100%; /* Ширина 100% для адаптивности */
  padding: 20px 20px 20px 73px;
  border-radius: 10px; /* Скругление углов элементов */
  color: #1e1e1e;
  background-color: #ffffffa6; /* Полупрозрачный фон для элементов */
  font-weight: 400;
  font-size: 24px; /* Размер шрифта для инпутов, как в дизайне Figma */
  line-height: 30px;
  border: none; /* Убираем стандартные границы */
  appearance: none; /* Убираем стандартный вид */
  transition: background-color 0.3s ease; /* Плавный переход фона при взаимодействии */
  cursor: pointer;
  background-position: left center; /* Центрирование фона */
  background-repeat: no-repeat; /* Не повторять фоновое изображение */
}

.dateSelector {
  background-image: url("../../media/icons/dateIcon.svg");
  background-position-x: 30px;
  background-size: 33px 29px !important;
  padding-left: 75px;
}

.dateSelector::placeholder {
  color: #1e1e1e; /* Задаем #1e1e1e цвет для текста placeholder */
}

/* Иконки для полей */
.searchIcon {
  transition: background-color 0.4s ease, padding 0.4s ease, font-size 0.4s ease; /* Плавный переход фона, паддингов и шрифта */
  position: absolute; /* Абсолютное позиционирование иконок */
  left: 32px; /* Отступ слева для иконок */
  top: 50%; /* Центрирование иконок по вертикали */
  transform: translateY(-50%); /* Центрирование иконок */
  pointer-events: none; /* Игнорирование событий клика для иконок */
}

.buttonWrapper {
  display: flex; /* Используем Flexbox для центрирования */
  justify-content: center; /* Центрируем кнопку горизонтально */
  margin-top: 20px; /* Отступ сверху для пространства */
}

/* Кнопка отправки формы */
.formButton {
  padding: 10px 60px; /* Отступы для кнопки */
  font-weight: 400;
  font-size: 24px; /* Размер шрифта для кнопки */
  line-height: 30px;
  border-radius: 8px; /* Скругление углов кнопки */
  background-color: transparent; /* Прозрачный фон для кнопки */
  border: 1px solid #ffffff; /* Белая граница для кнопки */
  color: #ffffff; /* Цвет текста кнопки */
  cursor: pointer; /* Курсор в виде указателя при наведении */
  transition: background-color 0.3s ease; /* Плавный переход фона при наведении */
}

/* Фокусировка для полей */
.dateSelector:focus,
.locationSelector:focus {
  outline: none; /* Удалить стандартный контур при фокусировке */
}

/* Скрываем отключенные опции в большинстве браузеров */
select option:disabled {
  display: none;
}

@media (max-width: 1300px) {
  .defaultForm {
    display: grid;
    width: 90%;
    padding: 24px;
  }

  .formButton {
    padding: 5px 60px;
  }

  .locationSelector,
  .dateSelector {
    font-weight: 300;
    font-size: 20px;
    padding-left: 50px;
    padding: 15px 0 15px 50px;
    transition: font-size 0.5s ease, padding-left 0.5s ease; /* Плавные изменения шрифта и отступов */
  }

  .dateSelector {
    background-size: 23px 24px !important; /* Размер иконки */
    background-position-x: 20px;
    padding-left: 50px;
  }

  .searchIcon {
    width: 23px;
    height: 24px;
    left: 20px;
  }

  .dateSelector {
    width: 290px;
  }

  .formTitle {
    font-size: 35px;
  }
}

@media (max-width: 1100px) {
  .dateSelector {
    width: 245px;
  }

  .formTitle {
    font-size: 30px;
  }
}

@media (max-width: 1024px) {
  .defaultForm {
    display: grid;
    width: 70%;
    height: auto;
  }

  .inputRow {
    display: grid;
    justify-content: space-between;
    gap: 16px;
  }

  .selectWrapper {
    grid-column-start: span 2;
  }

  .locationSelector,
  .dateSelector {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 15px 0 15px 45px;
  }

  .dateSelector {
    max-width: 250px;
    width: 100%;

    background-size: 16px 15px !important;
    padding-left: 45px;
    background-position-x: 14px;
  }

  .searchIcon {
    left: 16px;
    width: 15px;
    height: 15px;
  }

  .formTitle {
    padding-bottom: 24px;
  }

  .formGrid {
    gap: 16px;
  }
}

@media (max-width: 830px) {
  .defaultForm {
    display: grid;
    width: 90%;
    height: auto;
  }

  .formTitle {
    font-size: 24px;
  }

  .inputRow {
    display: grid;
    gap: 12px 19px;
  }

  .selectWrapper {
    grid-column-start: span 2;
  }

  .defaultForm {
    padding: 12px;
  }

  .buttonWrapper {
    margin-top: 12px;
  }

  .locationSelector,
  .dateSelector {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 11px 0 11px 34px;
  }

  .searchIcon {
    left: 12px;
  }

  .formButton {
    padding: 5px 60px;
    font-size: 20px;
  }

  .dateSelector {
    max-width: 255px;
    width: 100%;

    padding-left: 33px;
    background-position-x: 10px;
  }

  .formTitle {
    padding-bottom: 16px;
    line-height: 29px;
  }

  .formGrid {
    gap: 12px;
  }
}

@media (max-width: 400px) {
  .dateSelector {
    max-width: 160px;
    width: 100%;
  }

  .locationSelector,
  .dateSelector {
    font-size: 12px;
    padding: 11px 0 11px 30px;
  }

  .searchIcon {
    left: 10px;
  }

  .formButton {
    font-size: 18px;
  }
}
