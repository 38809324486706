/* Стили для контейнера продуктов */
.productsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px 20px;
  width: 100%;
  margin-bottom: 24px;
}

/* Стили для кнопки "Mai mult" */
.moreAutosButton {
  display: block;
  margin: 0 auto; /* Центрирование кнопки */
  padding: 5px 100px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  border-radius: 10px;
  color: #1E1E1E;
  background-color: #FFFFFF;
  border: 0.5px solid #1e1e1e;
  box-shadow: 0px 4px 14.8px 3px #00000040;
  cursor: pointer;
}

.noAutosParagraph {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  color: #1e1e1e;
}

.noCarsContainer {
  width: 100%;
  text-align: center;
}

.hidden {
  grid: none;
}

@media (max-width: 1350px) {
  .productsContainer {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media (max-width: 1200px) {
  .productsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1024px) {
  .productsContainer {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 40px;
  }

  .moreAutosButton {
    padding: 11px 85px;
    font-size: 20px;
    line-height: 25px;
  }

  .noAutosParagraph {
    padding: 40px;
    font-size: 18px;
  }
}

@media (max-width: 830px) {
  .productsContainer {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
    padding: 0 20px;
  }

  .moreAutosButton {
    padding: 6px 52px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .noAutosParagraph {
    padding: 20px;
    font-size: 12px;
  }
}
