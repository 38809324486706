.orderButton {
  padding: 10px 20px;
  border-radius: 8px;

  font-weight: 400;
  font-size: 22px;
  line-height: 30px;

  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #c2272d;
  box-shadow: 0px 2px 13.6px 2px #00000040;

  cursor: pointer;
}

@media (max-width: 1024px) {
  .orderButton {
    padding: 8px 33px;

    font-size: 14px;
    line-height: 17px;
  }
}
