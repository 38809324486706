* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "CustomFontName", sans-serif;
}

main {
  margin: 0 100px;
}

a {
  text-decoration: none;
  color: #1e1e1e;
}

/* Regular weight */
@font-face {
  font-family: "CustomFontName";
  src: url("/public/fonts/GoogleSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Medium weight */
@font-face {
  font-family: "CustomFontName";
  src: url("/public/fonts/GoogleSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Medium Italic */
@font-face {
  font-family: "CustomFontName";
  src: url("/public/fonts/GoogleSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Bold */
@font-face {
  font-family: "CustomFontName";
  src: url("/public/fonts/GoogleSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Bold Italic */
@font-face {
  font-family: "CustomFontName";
  src: url("/public/fonts/GoogleSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Italic */
@font-face {
  font-family: "CustomFontName";
  src: url("/public/fonts/GoogleSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Regular weight (400) */
@font-face {
  font-family: "Comfortaa";
  src: url("/public/fonts/Comfortaa.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* SemiBold weight */
@font-face {
  font-family: "Comfortaa";
  src: url("/public/fonts/Comfortaa.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@media (max-width: 1024px) {
  main {
    margin: 0;
  }
}
