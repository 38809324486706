/* Стили для контейнера продуктов */
.productsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: auto;

  margin-bottom: 24px;
}

/* Стили для кнопки "Mai mult" */
.moreAutosButton {
  display: block;
  margin: 0 auto; /* Центрирование кнопки */
  padding: 5px 100px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  border-radius: 10px;
  color: #1e1e1e;
  background-color: #ffffff;
  border: 0.5px solid #1e1e1e;
  box-shadow: 0px 4px 14.8px 3px #00000040;
  cursor: pointer;
  margin-bottom: 200px;
}

.noAutosParagraph {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  color: #1e1e1e;
}

@media (max-width: 1024px) {
  .productsContainer {
    gap: 20px;
    margin-bottom: 20px;
  }

  .moreAutosButton {
    padding: 11px 85px;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 100px;
  }

  .noAutosParagraph {
    padding: 40px;
    font-size: 18px;
  }
}

@media (max-width: 830px) {
  .moreAutosButton {
    padding: 6px 52px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 50px;
  }

  .noAutosParagraph {
    padding: 20px;
    font-size: 12px;
  }
}
