/* Стили для карточки продукта */
.productCard {
  padding: 60px 24px 24px 24px;
  border-radius: 20px;
  box-shadow: 0px 4px 14.2px 2px #00000040;
}

/* Стили для изображения автомобиля */
.productCard > img {
  max-width: 278px;
  height: 189px;
  border-radius: 10px;
  object-fit: contain;
  display: block;
  margin: 0 auto; /* Центрирование изображения */
}

/* Стили для информации о продукте */
.productInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

/* Стили для названия автомобиля */
.productInfo > h4 {
  padding-top: 37px;
  padding-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

/* Стили для абзацев с характеристиками автомобиля */
.productInfo div p {
  padding-bottom: 9px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

/* Стили для выделения текста в характеристиках */
.productInfo p span {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

/* Стили для последнего абзаца */
.lastParagraph {
  padding-top: 5px;
}

/* Стили для ссылки на детальную страницу автомобиля */
.productCard > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Стили для кнопки "Deschide" */
.linkButton {
  padding: 5px 100px;
  margin-top: 25px;
  font-weight: 700;
  font-size: 24px;
  border-radius: 10px;
  color: #1e1e1e;
  background-color: #ffffff;
  border: 0.5px solid #1e1e1e;
  cursor: pointer;
}

@media (max-width: 1710px) {
  .productCard {
    border-radius: 10px;
  }

  .productCard > img {
    max-width: 258px;
    height: 189px;
  }

  .productInfo > h4 {
    padding-top: 21px;
    padding-bottom: 13px;
  }

  .lastParagraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .linkButton {
    padding: 9px 66px;
    margin-top: 25px;
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .productCard > img {
    max-width: 238px;
    height: 189px;
  }
}

@media (max-width: 1456px) {
  .productCard > img {
    max-width: 218px;
    height: 189px;
  }
}

@media (max-width: 1350px) {
  .productCard > img {
    max-width: 258px;
    height: 214px;
  }
}

@media (max-width: 1200px) {
  .productCard > img {
    max-width: 304px;
    height: 214px;
  }

  .lastParagraph {
    margin: 0 auto;
  }

  .linkButton {
    margin-top: 12px;
  }
}

@media (max-width: 1024px) {
  .productCard > img {
    max-width: 304px;
    height: 214px;
  }
}

@media (max-width: 830px) {
  .productCard {
    padding: 20px 64px 18px 26px;
  }

  .productCard > img {
    max-width: 225px;
    height: 153px;
  }

  .productInfo > h4 {
    padding-top: 10px;
    padding-bottom: 8px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 25px;
  }

  .productInfo div {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 12px;
    line-height: 15px;
  }

  .productInfo div p {
    font-size: 11px;
    line-height: 15px;
  }

  .productInfo div p span {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }

  .linkButton {
    padding: 6px 50px;
    font-size: 14px;
  }
}

@media (max-width: 430px) {
  .productCard {
    padding: 20px;
  }

  .productCard > img {
    max-width: 205px;
    height: 153px;
  }
}
