.footer {
  display: flex;
  justify-content: space-between;

  border-top: 1px solid #000000;
  padding: 125px 100px;
}

.okRentaCar {
  width: 30%;
  color: #000000;
}

.okRentaCar > img {
  margin-bottom: 58px;

  width: 355px;
  height: 91px;
}

.okRentaCar > p {
  font-weight: 400;
  font-size: 24px;
}

.okRentaCar > p > span {
  font-weight: 700;
  font-size: 24px;
}

.rightSideFooter {
  width: 60%;
  display: flex;
  justify-content: center;
}

.linksContainer {
  padding-right: 130px;
}

.contactsContainer {
  padding-right: 130px;
}

.linksContainer h5,
.contactsContainer h5,
.socialMediaContainer h5 {
  padding-bottom: 23px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

.links,
.contacts,
.socialMedia {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1e1e1e;
}

.link {
  font-weight: 400;
  font-size: 16px;
}

.socialMediaIcons {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  gap: 20px;
}

.iconSize {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.copyright {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #1e1e1e;

  padding: 13px 0;
}

.promowebLink {
  font-family: "Comfortaa", sans-serif;
  color: #ffffff;
  text-decoration: underline;
}

.copyright p {
  font-family: "Comfortaa", sans-serif;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
}

.paragraphWithPadding {
  padding-bottom: 10px;
}

@media (max-width: 1560px) {
  .footer {
    padding: 100px 100px;
  }

  .okRentaCar {
    width: 40%;
    padding-right: 100px;
  }

  .okRentaCar > img {
    width: 325px;
  }

  .rightSideFooter {
    width: 60%;
  }

  .linksContainer {
    padding-right: 80px;
  }

  .contactsContainer {
    padding-right: 80px;
  }

  .link {
    font-weight: 400;
    font-size: 16px;
  }
}

@media (max-width: 1400px) {
  .okRentaCar {
    width: 40%;
    padding-right: 60px;
  }

  .okRentaCar > img {
    width: 295px;
  }

  .okRentaCar > p {
    font-size: 21px;
  }

  .rightSideFooter {
    width: 60%;
  }

  .linksContainer {
    padding-right: 40px;
  }

  .contactsContainer {
    padding-right: 50px;
  }

  .linksContainer h5,
  .contactsContainer h5,
  .socialMediaContainer h5 {
    font-size: 21px;
  }
}

@media (max-width: 1200px) {
  .footer {
    display: flex;
    align-items: center;
    flex-direction: column;

    border-top: 1px solid #000000;
    padding: 43px 100px 32px 100px;
  }

  .okRentaCar {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    padding-bottom: 92px;
  }

  .okRentaCar > img {
    margin-bottom: 0;
    width: 330px;
    height: 84px;
  }

  .okRentaCar > p {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
  }

  .okRentaCar > p > span {
    display: none;
  }

  .rightSideFooter {
    width: 100%;
  }

  .linksContainer {
    padding-right: 59px;
    padding-bottom: 98px;
  }

  .contactsContainer {
    padding-right: 59px;
  }

  .socialMediaIcons {
    padding-bottom: 32px;
    gap: 17px;
  }

  .copyright {
    padding: 9px 0;
  }

  .copyright p {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .footer {
    padding: 43px 50px 32px 50px;
  }

  .okRentaCar > img {
    width: 310px;
  }

  .okRentaCar > p {
    font-size: 21px;
  }

  .linksContainer {
    padding-right: 39px;
    padding-bottom: 88px;
  }

  .contactsContainer {
    padding-right: 39px;
  }

  .linksContainer h5,
  .contactsContainer h5,
  .socialMediaContainer h5 {
    font-size: 20px;
  }
}

@media (max-width: 830px) {
  .footer {
    padding: 20px 22px 16px 22px;
  }

  .okRentaCar {
    width: 50%;
    padding-bottom: 32px;
  }

  .okRentaCar > img {
    width: 169px;
    height: 43px;
    margin-bottom: 6px;
  }

  .okRentaCar > p {
    font-weight: 400;
    font-size: 12px;
  }

  .rightSideFooter {
    width: 100%;
    border-top: 1px solid #d9d9d9a3;
    display: flex;
    flex-direction: column;
  }

  .socialMediaContainer {
    display: none;
  }

  .linksContainer {
    padding-right: 0;
    padding-bottom: 16px;
  }

  .contactsContainer {
    padding-right: 0;
    padding-bottom: 0;
  }

  .linksContainer h5,
  .contactsContainer h5 {
    padding-bottom: 7px;
    font-size: 20px;
    line-height: 25px;
  }

  .linksContainer h5 {
    padding-top: 16px;
  }

  .links,
  .contacts,
  .socialMedia {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    gap: 8px;
  }

  .link {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .socialMediaIcons {
    padding-bottom: 16px;
    gap: 7px;
  }

  .iconSize {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .copyright {
    padding: 9px 0;
  }
}

@media (max-width: 408px) {
  .okRentaCar {
    width: 80%;
    padding-bottom: 22px;
  }
}
