.customHeaderPricesPage {
  margin: 40px 0 40px 0;
  height: 298px;
  position: relative;
}

.customHeaderPricesPage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 20px;
}

.customFormPricesPage {
  background-color: transparent;
  padding: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.headerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerSection > h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 16px;
}

.headerSection > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 40px;
}

.filterButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 40px;
}

.filterButtons > button {
  padding: 10px 40px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #1e1e1e;
  border: 1px solid #1e1e1e;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.filterButtons > button.active {
  font-weight: 600;
}

@media (max-width: 1024px) {
  .customHeaderPricesPage {
    margin: 0;
    height: 432px;
    margin-bottom: 40px;
  }

  .customHeaderPricesPage > img {
    border-radius: 0;
  }

  .customFormPricesPage {
    padding: 63px 155px;
  }

  .headerSection > p {
    padding-bottom: 24px;
  }

  .filterButtons {
    margin: 0 40px;
  }

  .filterButtons > button {
    padding: 10px 32px;
  }

  .filterButtons > button.active {
    font-weight: 600;
  }
}

@media (max-width: 830px) {
  .customHeaderPricesPage {
    height: 240px;
  }

  .customFormPricesPage {
    padding: 20px;
  }

  .headerSection {
    text-align: center;
    margin: 0 20px;
  }

  .headerSection > p {
    display: none;
  }

  .filterButtons {
    gap: 8px;
    padding-bottom: 20px;
    margin: 0 20px;
  }

  .filterButtons > button {
    padding: 5px;
    font-size: 14px;
    line-height: 17px;
  }

  .filterButtons > button.active {
    font-weight: 600;
  }
}
