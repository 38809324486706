.customHeaderSinglePage {
  margin: 40px 0;
  height: 230px;
  position: relative;
}

.customHeaderSinglePage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.customFormContainerPage {
  background-color: transparent;
  padding: 32px;
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.productTitle {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 34px;
}

.singlePageContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
}

.singlePageContainer > div:nth-child(3) {
  grid-row: span 2;
}

.productImg {
  max-width: 677px;
  height: 495px;
  margin-right: 86px;
  object-fit: contain;
}

.productInfo {
  margin-right: 53px;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
}

.productInfo > img {
  width: 30px;
  height: 30px;
}

.productInfo > div {
  display: flex;
  align-items: center;

  gap: 12px;
  padding-bottom: 25px;
}

.productInfo > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.pricesContainer {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px 4px #00000040;
  border-radius: 20px;
  height: auto;
}

.priceSection {
  padding: 20px 110px 0 24px;
}

.priceSection > h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 14px;
}

.pricesParagraphs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
}

.pricesParagraphs > div > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 11px;
}

.extraServices {
  padding: 0 100px 50px 23px;
}

.extraServices > h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 17px;
}

.extraServices > div {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  gap: 8px;
  padding-bottom: 12px;
}

.extraServices > div > label {
  cursor: pointer;
}

.extraServices > div > input {
  cursor: pointer;
}

.invoiceDetails {
  padding: 24px;
  border-radius: 20px;
  border-top: 1px solid #d3d3d3;
}

.invoiceDetails > h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 15px;
}

.invoiceDetails > div {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.preLastChild {
  padding-bottom: 15px; /* Space between services */
}

.lastService {
  padding: 0; /* No padding for the last service */
}

.lastChildInvoice {
  padding-top: 13px; /* Adds spacing before the total section */
}

.colorStrong {
  color: #c2272d;
}

.lastChildInvoice > p,
.lastChildInvoice > span {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

.excellentChoiceList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  padding-right: 341px;
  margin-bottom: 24px;
}

.excellentChoice > h4,
.mileOption > h4 {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 16px;
  color: #000000;
}

.excellentChoiceList > div {
  display: flex;
  align-items: center;
  gap: 4px;

  padding-bottom: 8px;
}

.excellentChoiceList > div > p,
.mileOption > div > p {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
}

.excellentChoiceList:nth-child(-n + 3) {
  padding-right: 40px;
}

.excellentChoiceList > img {
  width: 24px;
  height: 24px;
}

.mileOption > div {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-bottom: 24px;
}

.additionalOptions > h4 {
  font-family: "Comfortaa", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;

  padding-bottom: 16px;
  color: #000000;
}

.additionalOptions > div > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
}

.additionalOptions > div > p:first-child {
  padding-bottom: 8px;
}

.additionalOptions > div > p:last-child {
  padding-bottom: 200px;
}

input:focus,
textarea:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.additionalDiv,
.mobileVersion,
.additionalOptions2,
.additionalOptions3 {
  display: none;
}

@media (max-width: 1640px) {
  .productImg {
    max-width: 600px;
    margin-right: 46px;
  }

  .priceSection {
    padding: 24px;
  }

  .pricesParagraphs {
    gap: 20px;
  }

  .extraServices {
    padding: 24px;
  }

  .extraServices > div {
    gap: 8px;
    padding-bottom: 12px;
  }

  .excellentChoiceList {
    padding-right: 0;
    margin-bottom: 24px;
  }
}

@media (max-width: 1440px) {
  .productImg {
    max-width: 550px;
    margin-right: 26px;
  }

  .pricesParagraphs {
    gap: 0;
  }

  .productInfo {
    margin-right: 26px;
  }
}

@media (max-width: 1240px) {
  .productImg {
    max-width: 400px;
    margin-right: 16px;
  }

  .productInfo {
    margin-right: 16px;
  }
}

@media (max-width: 1150px) {
  .productImg {
    max-width: 360px;
  }
}

@media (max-width: 1024px) {
  .productImg,
  .productInfo {
    display: none;
  }

  .customHeaderSinglePage {
    margin: 0;
    margin-bottom: 40px;
    height: 432px;
  }

  .customHeaderSinglePage > img {
    border-radius: 0;
  }

  .customFormContainerPage {
    padding: 60px 155px;
  }

  .singlePageContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .productTitle {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 40px;
    margin-left: 40px;
  }

  .additionalDiv {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  }

  .productImg2 {
    max-width: 463px;
    height: 321px;
    margin-right: 83px;
    margin-bottom: 40px;
    object-fit: contain;
  }

  .productInfo2 {
    display: flex;
    align-items: flex-start;
  }

  .leftSide {
    margin-right: 84px;
  }

  .iconSize {
    width: 25px;
    height: 25px;
  }

  .leftSide,
  .rightSide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .productInfo2 > img {
    width: 30px;
    height: 30px;
  }

  .leftSide > div,
  .rightSide > div {
    display: flex;
    gap: 14px;
    padding-bottom: 14px;
  }

  .productInfo2 > p {
    font-size: 14px;
    line-height: 17px;
  }

  .pricesContainer {
    margin-right: 40px;
    margin-bottom: 100px;
  }

  .priceSection {
    padding: 24px;
  }

  .priceSection > h4 {
    font-size: 24px;
    padding-bottom: 24px;
  }

  .pricesParagraphs > div > p {
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 10px;
  }

  .extraServices {
    padding: 24px;
    padding: 24px;
  }

  .extraServices > h4 {
    font-size: 24px;
    padding-bottom: 14px;
  }

  .extraServices > div {
    font-size: 14px;
    line-height: 17px;
  }

  .invoiceDetails > h4 {
    font-size: 24px;
    padding-bottom: 13px;
  }

  .invoiceDetails > div {
    padding-bottom: 14px;

    font-size: 14px;
    line-height: 17px;
  }

  .detailsContainer {
    display: none;
  }
}

@media (max-width: 870px) {
  .priceSection {
    padding: 15px;
  }

  .extraServices {
    padding: 15px;
  }
}

@media (max-width: 840px) {
  .additionalDiv {
    display: none;
  }

  .leftSide3 > div > p,
  .rightSide3 > div > p,
  .lastParagraph > div > p {
    font-size: 14px;
  }

  .customHeaderSinglePage {
    height: 295px;
    margin-bottom: 40px;
  }

  .customFormContainerPage {
    padding: 20px;
  }

  .productTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 34px;
    margin-left: 20px;
  }

  .singlePageContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .mobileVersion {
    margin: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .productImg3 {
    max-width: 310px;
    height: 215px;
    margin-bottom: 32px;
  }

  .productInfo3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .leftSide3 > div,
  .rightSide3 > div,
  .lastParagraph > div {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 16px;
  }

  .iconSize3 {
    width: 30px;
    height: 30px;
  }

  .lastParagraph > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .lastParagraph {
    display: flex;
    justify-content: center;

    padding-bottom: 24px;
  }

  .leftSide3 > div > img,
  .rightSide3 > div > img {
    width: 30px;
    height: 30px;
  }

  .mobileVersionParagraph {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 25px;
  }

  .pricesContainer {
    margin: 0 20px 100px 20px;
  }

  .additionalOptions3 {
    display: flex;
    flex-direction: column;
  }

  .additionalOptions3 > h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    padding-bottom: 12px;
    color: #1a1a1a;
  }

  .additionalOptions3 > div > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #1a1a1a;
  }

  .additionalOptions3 > div > p:last-child {
    padding-bottom: 33px;
  }

  .priceSection {
    padding: 24px 24px 0 24px;
  }

  .priceSection > h4 {
    font-size: 24px;
    padding-bottom: 12px;
  }

  .pricesParagraphs {
    gap: 0;
  }

  .pricesParagraphs > div {
    padding-bottom: 24px;
  }

  .pricesParagraphs > div > p {
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 10px;
  }

  .extraServices {
    padding: 0 86px 50px 23px;
  }

  .extraServices > h4 {
    font-size: 24px;
    padding-bottom: 14px;
  }

  .extraServices > div {
    font-size: 14px;
    line-height: 17px;
  }

  .invoiceDetails {
    padding-bottom: 24px;
  }

  .invoiceDetails > h4 {
    font-size: 24px;
    padding-bottom: 13px;
  }

  .invoiceDetails > div {
    font-size: 14px;
    line-height: 17px;
  }
  
  .lastChildInvoice {
    padding-top: 10px;
    padding-bottom: 24px;
  }
}

@media (max-width: 370px) {
  .productImg3 {
    max-width: 280px;
    height: 200px;
  }

  .productInfo3 {
    gap: 15px;
  }

  .leftSide3 > div,
  .rightSide3 > div,
  .lastParagraph > div {
    gap: 5px;
    padding-bottom: 13px;
  }

  .lastParagraph > div {
    gap: 5px;
  }

  .lastParagraph {
    padding-bottom: 20px;
  }

  .leftSide3 > div > img,
  .rightSide3 > div > img {
    width: 28px;
    height: 28px;
  }

  .mobileVersionParagraph {
    margin-bottom: 20px;
  }

  .pricesContainer {
    margin: 0 20px 80px 20px;
  }

  .additionalOptions3 > h4 {
    padding-bottom: 10px;
  }

  .priceSection > h4 {
    font-size: 24px;
    padding-bottom: 12px;
  }

  .pricesParagraphs > div {
    padding-bottom: 16px;
  }

  .extraServices {
    padding: 0 23px 40px 23px;
  }

  .extraServices > h4 {
    padding-bottom: 12px;
  }

  .invoiceDetails > h4 {
    padding-bottom: 12px;
  }
}
