.burgerMenu {
  position: relative;
}

.burgerIcon {
  display: none;

  position: sticky;
  top: 0;
  right: 40px;
  z-index: 100;
}

.menu {
  background: #ffffff;
  border-left: 1px solid #ffffff;
  border-radius: 12px 0 12px 12px;
  position: fixed;
  top: 102px;
  right: 0;
  max-width: 351px;
  padding: 32px 136px 36px 32px;
  z-index: 999;
}

.navLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: 40px;
}

.link {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}

.contactsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contactItem {
  display: flex;
  align-items: center;
}

.contactLink {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 12px;
}

.iconSize {
  width: 29px;
  height: 29px;
}

@media (max-width: 1024px) {
  .burgerIcon {
    display: block;

    top: 33px;
    right: 40px;
    width: 56px;
    height: 34px;
    cursor: pointer;
  }

  .crossIcon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px 40px 0;
    width: 23px;
    height: 23px;
    cursor: pointer;
  }

  .overlay {
    position: fixed;
    top: 101px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1e1e1e91;
    z-index: 998;
  }
}

@media (max-width: 830px) {
  .burgerIcon {
    top: 24px;
    right: 20px;
    width: 30px;
    height: 18px;
  }

  .crossIcon {
    margin: 16px 20px 0;
    width: 16px;
    height: 14px;
  }

  .overlay {
    top: 65px;
  }

  .menu {
    top: 66px;
    right: 0;
    padding: 30px 80px 40px 24px;
  }

  .navLinks {
    gap: 10px;
    margin-bottom: 31px;
  }

  .link {
    font-size: 16px;
    line-height: 20px;
  }

  .contactsContainer {
    gap: 12px;
  }

  .contactLink {
    font-size: 14px;
    line-height: 17px;
    padding-left: 6px;
  }

  .iconSize {
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 530px) {
  .menu {
    top: 66px;
    right: 0;
    padding: 30px 80px 40px 24px;
  }
}

@media (max-width: 430px) {
  .burgerIcon {
    width: 25px;
    height: 16px;
  }
}
