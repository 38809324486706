/* Стили для карточки продукта */
.productCard {
  padding: 16px 40px;
  border-radius: 20px;
  box-shadow: 0px 4px 14.2px 2px #00000040;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 40px;
}

/* Стили для изображения автомобиля */
.image > img {
  max-width: 255px;
  height: 189px;
  object-fit: contain;
  display: block;
}

/* Стили для названия автомобиля */
.title > h4,
.title2 > h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

/* Стили для контейнера с характеристиками автомобиля */
.productInfoContainer > div,
.productInfoContainer2 > div {
  display: flex;
}

/* Стили для значков характеристик */
.productInfoContainer > div > img,
.productInfoContainer2 > div > img {
  width: 20px;
  height: 20px;
}

/* Стили для абзацев с характеристиками автомобиля */
.productInfoContainer > div > p,
.productInfoContainer2 > div > p {
  padding-bottom: 12px;
  padding-left: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

/* Стили для блока цен */
.prices {
  display: flex;
  flex-direction: column; /* Размещение элементов вертикально */
  align-items: center; /* Центрирование содержимого по горизонтали */
}

/* Стили для контейнера с кнопками цен */
.prices > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 5px;
}

/* Стили для кнопок цен */
.prices > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid #1e1e1e;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

/* Устанавливаем цвет текста внутри кнопки на #c2272d */
.prices > div > div > span {
  color: #c2272d;
}

/* Стили для кнопки "Deschide" */
.linkButton {
  padding: 9px 46px;
  margin-top: 12px;
  font-weight: 700;
  font-size: 24px;
  border-radius: 10px;
  color: #1e1e1e;
  background-color: #ffffff;
  border: 1px solid #1e1e1e;
  cursor: pointer;
}

.additionalDiv,
.mobileVersion {
  display: none;
}

.image > img,
.image2 > img,
.image3 > img {
  border-radius: 10px;
}


@media (max-width: 1200px) {
  .productCard {
    gap: 20px;
    padding: 16px 20px;
  }

  .image2 > img {
    margin-right: 20px;
  }

  .title > h4 {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .productCard {
    padding: 32px 61px 32px 32px;
    margin: 0 40px;
    display: flex;
    justify-content: space-between;
  }

  .image > img {
    height: 135px;
  }

  .title,
  .productInfoContainer {
    display: none;
  }

  .additionalDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .image2 > img {
    max-width: 196px;
  }

  .prices > div {
    grid-template-columns: repeat(2, 1fr);
    gap: 17px 12px;
  }

  .prices > div > div {
    padding: 5px 8px;
  }

  .linkButton {
    padding: 5px 55px;
    margin-top: 19px;
    font-size: 20px;
  }
}

@media (max-width: 830px) {
  .productCard {
    padding: 13px 5px 21px 5px;
    margin: 0 20px;
    gap: 0;
    display: flex;
    flex-direction: column;
  }

  .additionalDiv {
    display: none;
  }

  .image > img {
    display: none;
  }

  .image3 > img {
    max-width: 200px;
    height: 143px;
    margin-right: 6px;
  }

  .mobileVersion {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 4px;
  }

  .imageAndInfoContainer {
    display: flex;
  }

  .title3 > h4 {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }

  .productInfoContainer3 {
    padding-top: 16px;
  }
  
  .productInfoContainer3 > div > img {
    display: none;
  }

  .productInfoContainer3 > div > p {
    padding-bottom: 14px;
    padding-left: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .linkButton {
    padding: 6px 44px;
    margin-top: 15px;
    font-weight: 400;
    font-size: 16px;
  }
}

@media (max-width: 370px) {
  .productInfoContainer3 > div > p {
    padding-bottom: 6px;
  }

  .image3 > img {
    max-width: 180px;
  }
}
