.backgroundImg {
  background-image: url("../../media/images/transparentWhiteCar.svg");

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  border-radius: 20px;
  border: 1px solid #1e1e1e00;
  box-shadow: 0px 4px 25.1px 0px #00000040;
  margin: 40px 0 213px;
}

.content {
  padding: 24px 0 96px 62px;
  max-width: 90%;
}

.content h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 40px;
}
.content h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 16px;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 40px;
}

.whyChooseUsSection > ul {
  padding-left: 22px;
}

.benefitItem {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 18px;
}

.thankYou {
  margin: 0 32px 96px 62px;
}

@media (max-width: 1024px) {
  .backgroundImg {
    margin: 40px;
  }
}

@media (max-width: 830px) {
  .backgroundImg {
    margin: 20px 0 80px 0;
    border-radius: 20px;
    box-shadow: 0px 4px 34.9px 10px #00000040;
  }

  .content {
    padding: 20px;
  }

  .content h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 40px;
  }

  .content h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .description {
    padding-bottom: 24px;
  }

  .thankYou {
    text-align: center;
    margin: 0 20px 40px 20px;
  }
}
