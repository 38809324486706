.leftContainer {
  padding: 24px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 34.9px 10px #00000040;
  height: auto;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 12px;
}

.leftContainerContent {
  display: flex;
  flex-direction: column;
}

.leftContainerContent {
  margin-bottom: 12px;
}

.leftContainerContent > div > label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  cursor: pointer;
}

.leftContainerContent > div {
  padding-bottom: 8px;
}

.leftContainerContent > div:last-child {
  padding-bottom: 0;
}

.leftContainerContent input[type="checkbox"] {
  cursor: pointer; /* Указатель курсора при наведении на чекбокс */
}
