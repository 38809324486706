.header {
  margin: 30px 0 90px 0;
  position: relative;
  width: 100%;
  border-radius: 20px;
}

.headerImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .header {
    margin: 0;
    margin-bottom: 40px;
  }
  
  .headerImage {
    border-radius: 0;
    height: 1252px;
  }
}

@media (max-width: 830px) {
  .headerImage {
    height: 710px;
  }
}
