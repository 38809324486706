.customHeaderAutosPage {
  margin: 40px 0 40px 0;
  height: 298px;
  position: relative;
}

.customHeaderAutosPage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 20px;
}

.customFormAutosPage {
  background-color: transparent;
  padding: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.mainContainer {
  display: grid;
  grid-template-columns: 1fr 3fr; /* Left side (1fr) and right side (3fr) */
  gap: 20px;
}

.leftSideContainer {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  margin-bottom: 40px;
}

.rightSideContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
}

.leftSideContainer > h2 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;

  margin-bottom: 42px;
}

.upperFilterSide,
.filterHeader {
  display: none;
}

@media (max-width: 1024px) {
  .customHeaderAutosPage {
    margin: 0;
    margin-bottom: 35px;
    height: 432px;
  }

  .customHeaderAutosPage > img {
    border-radius: 0;
  }

  .customFormAutosPage {
    padding: 63px 155px;
  }

  .filterSide {
    display: flex;
    flex-direction: column;
    margin: 0 40px;
  }

  .upperFilterSide {
    display: flex;
    justify-content: space-between;

    padding-bottom: 24px;
  }

  .filterIcon {
    width: 39px;
    height: 39px;
    cursor: pointer;
  }

  .filterHeader {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 24px;
  }

  .thickCrossIcon {
    display: flex;
    width: 34px;
    height: 34px;
    cursor: pointer;
  }

  .hideH2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  .hidenParagraph {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
  }

  .mainContainer {
    grid: none;
    gap: 0;
  }

  .leftSideContainer {
    display: flex;
  }

  .leftSideContainer.open {
    margin: 0 40px 100px 40px;
  }

  .leftSideContainer > h2 {
    display: none;
  }
}

@media (max-width: 830px) {
  .customHeaderAutosPage {
    height: 295px;
    margin-bottom: 40px;
  }

  .customFormAutosPage {
    padding: 19px 20px;
  }

  .filterSide {
    margin: 0 20px;
  }

  .upperFilterSide {
    padding-bottom: 16px;
  }

  .filterIcon {
    width: 27px;
    height: 27px;
  }

  .thickCrossIcon {
    width: 14px;
    height: 14px;
  }

  .hidenParagraph {
    font-size: 24px;
    line-height: 30px;
  }

  .leftSideContainer.open {
    margin: 0 20px 50px 20px;
  }

  .upperFilterSide > h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }

  .upperFilterSide {
    display: flex;
  }

  .filterHeader {
    display: flex;
  }

  .upperFilterSide {
    padding-bottom: 16px;
  }

  .filterIcon {
    width: 27px;
    height: 27px;
  }

  .filterHeader {
    padding-top: 40px;
    padding-bottom: 16px;
  }

  .thickCrossIcon {
    width: 16px;
    height: 14px;
  }

  .hideH2 {
    font-size: 24px;
    line-height: 30px;
  }

  .hidenParagraph {
    font-size: 24px;
    line-height: 30px;
  }
}
