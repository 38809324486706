.recommendations {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  width: 100%;
  padding: 14px 0 15px 80px;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 13.2px -4px #00000040;
  border-radius: 10px;

  margin-bottom: 42px;
}

.recommendations > div {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.recommendations > div:hover,
.recommendations > .active {
  font-weight: 600;
}

@media (max-width: 1024px) {
  .recommendations {
    margin-bottom: 24px;
  }
}

@media (max-width: 830px) {
  .recommendations {
    gap: 48px;
    padding: 14px 0 15px 28px;
    margin-bottom: 16px;
  }

  .recommendations > div {
    font-size: 12px;
    line-height: 15px;
  }

  .recommendations > div:hover,
  .recommendations > .active {
    font-weight: 700;
  }
}

@media (max-width: 460px) {
  .recommendations {
    padding: 14px 0 15px 18px;
    gap: 28px;
  }
}
