.input {
  width: 100%; /* Ширина 100% для адаптивности */
  padding: 20px 20px 20px 73px; /* Универсальные отступы */
  border-radius: 10px; /* Скругление углов элементов */
  background-color: #ffffffa6; /* Полупрозрачный фон для элементов */
  font-weight: 400;
  font-size: 24px; /* Размер шрифта для инпутов, как в дизайне Figma */
  line-height: 30px;
  border: none; /* Убираем стандартные границы */
  appearance: none; /* Убираем стандартный вид */
  transition: background-color 0.3s ease; /* Плавный переход фона при взаимодействии */
  cursor: pointer;
}

.input:focus {
  outline: none; /* Убираем стандартный контур */
  transform: none; /* Убираем любое масштабирование или трансформации при фокусировке */
}

.timeIcon {
  position: absolute; /* Абсолютное позиционирование иконок */
  left: 32px; /* Отступ слева для иконок */
  top: 50%; /* Центрирование иконок по вертикали */
  transform: translateY(-50%); /* Центрирование иконок */
  pointer-events: none; /* Игнорирование событий клика для иконок */
}

.timeWrapper {
  position: relative; /* Позволяет располагать дропдаун относительно инпута */
  width: 188px;
}

.timeDropdown {
  width: 100%; /* Убедитесь, что ширина выпадающего меню равна ширине инпута */
  position: absolute; /* Позволяет дропдауну позиционироваться под инпутом */
  background-color: #ffffff; /* Цвет фона вашего выпадающего меню */
  box-shadow: 0px 4px 34.9px 10px #00000040;
  border-radius: 8px; /* Скругление углов */
  margin-top: 5px;
  z-index: 1000; /* Обеспечиваем, что меню поверх других элементов */
  display: flex; /* Используем flexbox для упорядочивания элементов */
}

.hoursWrapper,
.minutesWrapper {
  width: 100%; /* Ширина оберток для часов и минут */
  align-items: center;
  display: inline-flex;
  flex-direction: column; /* Вертикальное выравнивание */
  max-height: 205px; /* Максимальная высота */
  overflow-y: auto; /* Позволяем вертикальную прокрутку, если контент превышает высоту */
  overflow-x: hidden;
}

.hourOption,
.minuteOption {
  padding: 8px 37px; /* Отступы для каждой опции */
  cursor: pointer; /* Указатель при наведении */
  transition: background-color 0.3s; /* Плавный переход фона */
  height: 30px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.hourOption:hover,
.minuteOption:hover {
  background-color: #f0f0f0; /* Цвет фона при наведении */
  color: #9b9b9b;
}

/* Скрытие полос прокрутки */
.hoursWrapper::-webkit-scrollbar,
.minutesWrapper::-webkit-scrollbar {
  display: none; /* Скрываем полосу прокрутки */
}

.minutesWrapper {
  padding-left: 10px; /* Отступ для минут */
}

@media (max-width: 1300px) {
  .timeWrapper {
    width: 150px;
  }

  .input {
    font-weight: 300;
    font-size: 20px;
    padding-left: 50px;
    padding: 15px 0 15px 50px;
    transition: font-size 0.5s ease, padding-left 0.5s ease; /* Плавные изменения шрифта и отступов */
  }

  .timeIcon {
    width: 23px;
    height: 24px;
    left: 20px;
  }
}

@media (max-width: 1100px) {
  .timeWrapper {
    width: 130px;
  }
}

@media (max-width: 1024px) {
  .timeWrapper {
    width: 250px;
  }

  .input {
    padding: 15px 0 15px 45px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .timeIcon {
    left: 16px;
    width: 15px;
    height: 15px;
  }

  .hourOption,
  .minuteOption {
    font-size: 16px;
  }
}

@media (max-width: 830px) {
  .input {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 11px 0 11px 34px;
  }

  .timeIcon {
    left: 12px;
  }

  .timeWrapper {
    width: 255px;
  }

  .hourOption,
  .minuteOption {
    font-size: 14px;
  }
}

@media (max-width: 623px) {
  .timeWrapper {
    width: 200px;
  }
}

@media (max-width: 514px) {
  .timeWrapper {
    width: 150px;
  }
}

@media (max-width: 400px) {
  .timeWrapper {
    max-width: 140px;
    width: 100%;
  }

  .input {
    font-size: 12px;
    padding: 11px 0 11px 30px;
  }

  .timeIcon {
    left: 10px;
  }

  .hourOption,
  .minuteOption {
    font-size: 12px;
  }
}
