.mapBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 886px;
  margin: 0 auto;
  padding-bottom: 200px;
}

.mapBlock > h2 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  padding-bottom: 24px;
}

.googleMap {
  width: 100%;
  height: 536px;
  border: none;
  border-radius: 20px;
}

@media (max-width: 830px) {
  .mapBlock {
    padding-bottom: 100px;
  }
}
