.contactsContainer {
  display: flex;
  justify-content: space-between;
}

.leftSideContainer {
  padding: 40px 0;
}

.leftSideContainer > h2 {
  padding-bottom: 32px;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
}

.rightSideContainer {
  width: 100%;
  max-width: 882px;
  margin: 121px 0;
  display: flex;
  justify-content: flex-end;
}

.workingHours {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  color: #000000;
  padding-bottom: 24px;
}

.workingHours > h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

.workingHours > p {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}

.iconSize {
  width: 40px;
  height: 40px;
}

.contactDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.contactItem {
  display: flex;
  align-items: center;
}

.innerContent {
  padding-left: 9px;
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}

.googleMaps {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .contactsContainer {
    margin: 0 40px;
  }

  .leftSideContainer {
    padding: 40px 0;
  }

  .leftSideContainer > h2 {
    padding-bottom: 24px;
  }

  .workingHours {
    padding-bottom: 40px;
  }
  
  .contactDetails {
    gap: 16px;
  }

  .googleMaps {
    width: 80%;
    margin: 24px 69px 96px 69px;
  }
}

@media (max-width: 830px) {
  .contactsContainer {
    flex-direction: column;
    padding-bottom: 40px;
  }

  .leftSideContainer > h2 {
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }

  .rightSideContainer {
    margin: 0;
    justify-content: center;
  }

  .workingHours {
    padding-bottom: 16px;
  }

  .workingHours > h4 {
    font-size: 12px;
    line-height: 15px;
  }

  .workingHours > p {
    font-size: 14px;
    line-height: 17px;
  }

  .iconSize {
    width: 26px;
    height: 26px;
  }

  .contactDetails {
    gap: 12px;
  }

  .innerContent {
    padding-left: 14px;
    font-size: 16px;
    line-height: 20px;
  }

  .googleMaps {
    width: 90%;
    margin: 0 auto;
  }
}