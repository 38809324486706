.navMenu {
  width: 100%;
  white-space: nowrap;

  position: sticky;
  z-index: 999;
  top: 0;
}

.upperNavMenu,
.lowerNavMenu {
  display: flex;
  justify-content: space-between;

  background-color: #ffffff;
  border-bottom: 1px solid #1e1e1e;
  color: #1e1e1e;
  padding: 0 100px;
}

.upperNavMenu {
  height: 100px;
}

.lowerNavMenu {
  height: 56px;
}

.rightSide {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rightSide > div {
  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

.rightSide > div:first-child {
  display: flex;
  justify-content: flex-end;
}

.contactLink > span {
  color: #c2272d;
}

.icons {
  width: 29px;
  height: 29px;

  padding-right: 8px;
}

.logo {
  width: 281px;
  height: 72px;

  margin-top: 16px;
  margin-bottom: 12px;
}

.links {
  display: flex;
  align-items: center;
}

.links a {
  font-weight: 400;
  font-size: 24px;

  line-height: 30px;
  cursor: pointer;

  padding-right: 70px;
}





.link:hover {
  color: #c2272d;
}

.activeLink {
  color: #c2272d;
}

@media (max-width: 1300px) {
  .links a {
    padding-right: 55px;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
  }

 

  .rightSide > div {
    font-weight: 600;
    font-size: 22px;
  }
}

@media (max-width: 1200px) {
  .links a {
    padding-right: 45px;
  }
}

@media (max-width: 1024px) {
  .lowerNavMenu,
  .rightSide {
    display: none;
  }

  .upperNavMenu {
    padding: 0 40px 0 25px;
  }

  .logo {
    width: 273px;
    height: 62px;
  }
}

@media (max-width: 830px) {
  .upperNavMenu {
    height: 66px;
    padding: 0 20px 0 0;
  }

  .logo {
    width: 253px;
    height: 52px;
    margin: 6px 0;
  }
}

@media (max-width: 430px) {
  .upperNavMenu {
    padding: 0 20px;
  }

  .logo {
    width: 169px;
    height: 43px;
    margin: 12px 0;
  }
}
