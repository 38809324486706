.checkboxLabel {
  display: flex;
  align-items: center;
}

.checkboxInput {
  display: none; /* Скрываем стандартный чекбокс */
}

.checkboxCustom {
  width: 20px;
  height: 20px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  margin-right: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; /* Центрируем изображение внутри чекбокса */
}

.vIcon {
  width: 14px; /* Устанавливаем размер изображения */
  height: 11px; /* Устанавливаем размер изображения */
}

.checkboxCustom::after {
  transition: transform 0.2s ease-in-out; /* Плавный переход */
}
