.mainFormContainer {
  width: 90%;
  max-width: 882px;
}

.contactForm {
  padding: 24px;
  box-shadow: 0px 4px 21.4px 4px #00000040;
  background-color: #ffffff;
  border-radius: 10px;
}

.contactFormContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.leftSideInputs,
.rightSideTextArea {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.leftSideInputs > input,
.rightSideTextArea > textarea {
  background-color: #f4f4f4;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #9b9b9b;
  border: none;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
}

.rightSideTextArea > textarea {
  resize: none;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

.formButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}

.formButton > button {
  padding: 8px 96px;
  border-radius: 10px;
  color: #1e1e1e;
  background-color: #ffffff;
  border: 1px solid #c2272d;
  box-shadow: 0px 4px 14.8px 3px #00000040;

  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 12px; /* Маленький размер шрифта */
  margin-top: -18.9px; /* Отступ сверху */
  margin-bottom: -16px;
  position: relative;
}

@media (max-width: 1024px) {
  .contactFormContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .leftSideInputs,
  .rightSideTextArea {
    gap: 8px;
  }

  .leftSideInputs > input,
  .rightSideTextArea > textarea {
    font-size: 14px;
    line-height: 17px;
    padding: 10px 0 10px 21px;
  }

  .formButton {
    padding-top: 20px;
  }

  .formButton > button {
    padding: 8px 55px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 830px) {
  .contactForm {
    padding: 21px 28px;
  }

  .mainFormContainer {
    width: 100%;
  }

  .leftSideInputs > input,
  .rightSideTextArea > textarea {
    padding: 9px 16px;
  }
}
